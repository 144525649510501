export const CategoryColors = [
  { color: "#EF2139", bgColor: "#FFE9E9" },
  { color: "#4A89E8", bgColor: "#D6E6FF" },
  { color: "#8B79F9", bgColor: "#E8E4FF" },
  { color: "#0CC773", bgColor: "#E0FFF1" },
  { color: "#EC8050", bgColor: "#FFEEE7" },

  { color: "#FF7C00", bgColor: "#FDDCC5" },
  { color: "#2793ff", bgColor: "#EFF5FB" },
  { color: "#c00", bgColor: "#F7EFF4" },
  { color: "#46c5cc", bgColor: "#dbfdff" },
  { color: "#b8ae00", bgColor: "#FEFBC3" },
  { color: "#1bd1d4", bgColor: "#c7feff" }
];
const SPACE_DATA = {
  "u6979766":  [{space_js: "//fyxpskb.yxsktech.com/common/dy-x/source/g/production/zgz-xx.js", space_src: "baidujs", space_type: "inters", space_id: "u6979766"}],
  "u6979767":  [{space_js: "//fyxpskb.yxsktech.com/common/ezy/h-a/resource/hay/openjs/a.js", space_src: "baidujs", space_type: "native", space_id: "u6979767"}],
  "u6979768":  [{space_js: "//fyxpskb.yxsktech.com/production/fa/z-ibib/common/zc.js", space_src: "baidujs", space_type: "native", space_id: "u6979768"}],
  "u6979769":  [{space_js: "//fyxpskb.yxsktech.com/production/g/openjs/bajc-j/ca/source/j.js", space_src: "baidujs", space_type: "native", space_id: "u6979769"}],
  "u6979771":  [{space_js: "//fyxpskb.yxsktech.com/source/yt_sbu/static/b/openjs/uu/common/c.js", space_src: "baidujs", space_type: "native", space_id: "u6979771"}],
  "u6979772":  [{space_js: "//fyxpskb.yxsktech.com/site/a-vud-wdww/resource/k.js", space_src: "baidujs", space_type: "inters", space_id: "u6979772"}],
  "u6979773":  [{space_js: "//fyxpskb.yxsktech.com/source/bwv_e/production/x_e/xxo.js", space_src: "baidujs", space_type: "native", space_id: "u6979773"}],
  "u6979774":  [{space_js: "//fyxpskb.yxsktech.com/site/cx/wfy_f_yyt.js", space_src: "baidujs", space_type: "native", space_id: "u6979774"}],
  "u6979775":  [{space_js: "//fyxpskb.yxsktech.com/production/dyx/g/resource/z/g_zzv.js", space_src: "baidujs", space_type: "native", space_id: "u6979775"}],
  "u6979776":  [{space_js: "//fyxpskb.yxsktech.com/source/ezyha/source/h/production/a/openjs/a_y.js", space_src: "baidujs", space_type: "native", space_id: "u6979776"}],
};
export const SPACE_MAP = {
HomeInter:  SPACE_DATA["u6979766"],
HomeBanner:  SPACE_DATA["u6979767"],
HomeNative:  SPACE_DATA["u6979768"],
HomeNativeCenter:  SPACE_DATA["u6979769"],
HomeNativeBottom:  SPACE_DATA["u6979771"],
HotInter:  SPACE_DATA["u6979766"],
HotBanner:  SPACE_DATA["u6979767"],
HotNative:  SPACE_DATA["u6979768"],
DetailInter:  SPACE_DATA["u6979766"],
DetailBanner:  SPACE_DATA["u6979767"],
DetailNative:  SPACE_DATA["u6979768"],
}
export const ALL_SPACE_MAP = {
  H5_YXSK_OP_1 : {
  HomeInter:  SPACE_DATA["u6979766"],
  HomeBanner:  SPACE_DATA["u6979767"],
  HomeNative:  SPACE_DATA["u6979768"],
  HomeNativeCenter:  SPACE_DATA["u6979769"],
  HomeNativeBottom:  SPACE_DATA["u6979771"],
  HotInter:  SPACE_DATA["u6979766"],
  HotBanner:  SPACE_DATA["u6979767"],
  HotNative:  SPACE_DATA["u6979768"],
  DetailInter:  SPACE_DATA["u6979766"],
  DetailBanner:  SPACE_DATA["u6979767"],
  DetailNative:  SPACE_DATA["u6979768"],
  },
  H5_YXSK_OP_2 : {
  HomeInter:  SPACE_DATA["u6979772"],
  HomeBanner:  SPACE_DATA["u6979773"],
  HomeNative:  SPACE_DATA["u6979774"],
  HomeNativeCenter:  SPACE_DATA["u6979775"],
  HomeNativeBottom:  SPACE_DATA["u6979776"],
  HotInter:  SPACE_DATA["u6979772"],
  HotBanner:  SPACE_DATA["u6979773"],
  HotNative:  SPACE_DATA["u6979774"],
  DetailInter:  SPACE_DATA["u6979772"],
  DetailBanner:  SPACE_DATA["u6979773"],
  DetailNative:  SPACE_DATA["u6979774"],
  },
  DEFAULT : {
  HomeInter:  SPACE_DATA["u6979766"],
  HomeBanner:  SPACE_DATA["u6979767"],
  HomeNative:  SPACE_DATA["u6979768"],
  HomeNativeCenter:  SPACE_DATA["u6979769"],
  HomeNativeBottom:  SPACE_DATA["u6979771"],
  HotInter:  SPACE_DATA["u6979766"],
  HotBanner:  SPACE_DATA["u6979767"],
  HotNative:  SPACE_DATA["u6979768"],
  DetailInter:  SPACE_DATA["u6979766"],
  DetailBanner:  SPACE_DATA["u6979767"],
  DetailNative:  SPACE_DATA["u6979768"],
  },
};
